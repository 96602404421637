/* global jQuery, Waypoint */
// import counterUp from 'counterup2'
// console.log('index.mjs')

;(function (window, document, $, Waypoint) {
  window.getUserEmail = function () {
    let email = ''

    const cookies = document.cookie.split(';')
    for (let i = 0, iLen = cookies.length; i < iLen; ++i) {
      const cookie = cookies[i].split('=')
      const cookieName = $.trim(cookie[0])

      if (cookieName === 'user.email') {
        const cookieValue = $.trim(cookie[1])
        email = cookieValue
        break
      }
    }
    return email
  }

  window.isLoggedIn = function () {
    const loggedIn = $('body').hasClass('logged-in')
    return loggedIn
  }

  window.clearUserEmailCookie = function () {
    document.cookie = 'user.email=;path=/'
  }

  window.setUserEmailCookie = function (email) {
    document.cookie = 'user.email=' + email + ';path=/'
  }

  initializeSiteHeader()
  initializeFrontPage()
  initializeCheckoutPage()
  initializeFreeTrialForm()

  function initializeSiteHeader () {
    const $collapsible = $('.site-header__menu').collapse({ toggle: false })
    const $menuToggler = $('.menu-toggler')

    $collapsible.on('hide.bs.collapse', function () {
      $menuToggler.find('.menu-toggler__icon').removeClass('is-active')
      $('html').removeClass('site-menu-is-open')
    })

    $collapsible.on('show.bs.collapse', function () {
      $menuToggler.find('.menu-toggler__icon').addClass('is-active')
      $('html').addClass('site-menu-is-open')
    })

    $menuToggler.on('click', function () {
      $collapsible.collapse('toggle')
    })

    // eslint-disable-next-line no-new
    new Waypoint({
      element: document.querySelector('.pg__body'),
      handler: function (direction) {
        if (direction === 'up') {
          $('html').removeClass('is-scrolled')
        }
        else {
          $('html').addClass('is-scrolled')
        }
      },
      offset: -1,
    })
  }

  function initializeFrontPage () {
    if (document.location.pathname === '/') {
      window.clearUserEmailCookie()
      __initializeFrontPageSlideshow()
      __initializeTestimonialSlideshow()
      // __initializeFrontPageByTheNumbersCounter()
    }
  }

  function __initializeFrontPageSlideshow () {
    $('.front-page-slideshow_js')
      .on('init', function (slick) {
        // $('.practical-and-effective-section').css({
        //   background: 'linear-gradient(90deg, rgba(53, 26, 26, 0.5) 0%, rgba(53, 26, 26, 0.2) 100%)',
        // })
      })
      .slick({
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        draggable: false,
        dots: false,
        fade: true,
        infinite: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        speed: 750,
        swipe: false,
      })
  }

  // function __initializeFrontPageByTheNumbersCounter () {
  //   // console.log('__initializeFrontPageByTheNumbersCounter')
  //   const byTheNumbersSectionElem = document.querySelector('.by-the-numbers-section')
  //   if (byTheNumbersSectionElem) {
  //     const waypoint = new Waypoint({
  //       element: byTheNumbersSectionElem,
  //       handler: function (direction) {
  //         const counterElmts = document.querySelectorAll('.by-the-numbers-box .amount')
  //         if (counterElmts) {
  //           counterElmts.forEach((counterElmt) => {
  //             counterUp.default(counterElmt, { delay: 100 })
  //           })
  //         }
  //         // Only do it once per page show.
  //         waypoint.disable()
  //       },
  //       offset: '100%',
  //     })
  //   }
  // }

  function initializeCheckoutPage () {
    if (document.location.pathname === '/checkout/') {
    }
  }

  function __initializeTestimonialSlideshow () {
    $('.testimonials-slideshow_js')
      .on('init', function (slick) {
      })
      .slick({
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        // draggable: false,
        dots: false,
        // fade: true,
        infinite: true,
        // pauseOnFocus: false,
        // pauseOnHover: false,
        speed: 750,
        // swipe: false,
      })
  }

  function initializeFreeTrialForm () {
    const email = window.getUserEmail()
    const $emailForm = $('.email-form_js form')

    // Set email from cookie.
    if (email) {
      $emailForm.each(function () {
        $(this).get(0).elements.email.value = email
      })
    }

    $emailForm.on('submit', function (evt) {
      evt.preventDefault()
      const form = this
      const $form = $(form)
      const $submitBtn = $form.find('button[type=submit]')

      $submitBtn.disabled = true

      window.setUserEmailCookie(form.elements.email.value)

      // Note: We are relying on a rewrite rule to add query parameters.
      document.location.href = '/checkout/'
    })
  }

})(window, document, jQuery, Waypoint)
